import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery } from "gatsby"
import StandardContainer from "./StandardContainer"

const BackgroundContainer = ({ bgFluid = null, children, className }) => {
  const data = useStaticQuery(graphql`
    query {
      defaultBackground: file(relativePath: { eq: "second_banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const fluid = bgFluid || data.defaultBackground.childImageSharp.fluid

  const backgroundFluidImageStack = [
    fluid,
    `linear-gradient(0deg, rgba(255,255,255,1), rgba(255,255,255,0.75) 20%, rgba(255,255,255,0.65) 80%,rgba(255,255,255,1))`,
  ].reverse()

  return (
    <BackgroundImage fluid={backgroundFluidImageStack}>
      <StandardContainer className={` ${className}`}>
        {children}
      </StandardContainer>
    </BackgroundImage>
  )
}

export default BackgroundContainer
