import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// import urlBuilder from "@sanity/image-url"
// import PortableText from "@sanity/block-content-to-react"
import { FaPhone, FaStoreAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Banner from "../components/banner"
import ContactContainer from "../components/contactContainer"
import SideHeaderContainer from "../components/sideHeaderContainer"
import HeroContainer from "../components/heroContainer"

import ContactForm from "../components/contactForm"
import PageTitle from "../components/PageTitle"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const ContactPage = ({ data }) => {
  // custom header from Sanity. If none provided, this will be null
  const heroFluid = data.heroImage.childImageSharp.fluid

  const contactCards = [
    {
      name: "Full Name",
      title: "Title",
      email: "email@gmail.com",
      phone: "7374971",
      extension: "1234",
    },
    {
      name: "Full Name",
      title: "Title",
      email: "email@gmail.com",
      phone: "7374971",
      extension: "1234",
    },
    {
      name: "Full Name",
      title: "Title",
      email: "email@gmail.com",
      phone: "7374971",
      extension: "1234",
    },
    {
      name: "Full Name",
      title: "Title",
      email: "email@gmail.com",
      phone: "7374971",
      extension: "1234",
    },
    {
      name: "Full Name",
      title: "Title",
      email: "email@gmail.com",
      phone: "7374971",
      extension: "1234",
    },
    {
      name: "Full Name",
      title: "Title",
      email: "email@gmail.com",
      phone: "7374971",
      extension: "1234",
    },
  ]
  const heroProps = {
    fluid: heroFluid,

    gradient: true,
  }
  return (
    //pass custom header to Layout
    <Layout>
      <SEO title="Contact Us" />

      <HeroContainer props={heroProps} />

      <BackgroundContainer className="text-secondary">
        <PageTitle text="Contact Us" />
        {/* Top Sections */}
        <div className="grid lg:grid-cols-2 justify-items-center gap-y-10 my-20 mx-5">
          <div>
            <div className="">
              <h3>Stephan Smit</h3>
              <p className="p-0">
                Sioux Lookout Regional Physicians' Services Inc.
                <br />
                Sioux Lookout First Nations Health Authority
                <br />
                Phone: (807) 738-0414
                <br />
                Toll Free: 1-800-842-0681
              </p>
              <p>
                Email:{" "}
                <a
                  href="mailto:recruitment@slfnha.com"
                  className="underline font-bold hover:text-fifth"
                >
                  recruitment@slfnha.com
                </a>
              </p>
            </div>
          </div>
          <div className="">
            <h2 className="text-2xl text-center">Send us a message</h2>
            <ContactForm />
          </div>
        </div>
      </BackgroundContainer>

      {/* <SideHeaderContainer
        headerText="Our Staff"
        contacts={contactCards}
        height="1/2"
        dividerColor="fifth"
      />
      <SideHeaderContainer
        headerText="Our Staff"
        contacts={contactCards}
        height="screen"
        dividerColor="fifth"
      /> */}
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "Beautiful Fall Cloudy Day.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBanner: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
